function Nav() {
  return (
    <>
      <div id="head">
        <nav>
          <div id="buttons">
            <a href="/">Home</a>
            <a href="history">Our History</a>
            <a href="events">Events</a>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Nav;
