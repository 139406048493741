import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./Styles/index.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Events from "./Pages/Events";
import "./Images/bonsaiSocIcon.ico";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/history" element={<About />} />
        <Route path="events" element={<Events />} />
      </Routes>
    </Router>
  );
}

export default App;
